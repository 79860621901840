import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import store from '../store/index.js'
Vue.prototype.axios = axios
Vue.prototype.qs = qs

// import { Message } from 'element-ui';
import { Notify } from 'vant'

function ajax(url, params, method, headers = {}, isHideLoading = '') {
    return new Promise((resolve, reject) => {
        var http = {}
        if (method == 'post' || method == 'POST') {
            http = {
                method: 'post',
                data: JSON.stringify(params)
            }
        } else if (method == 'delete') {
            http = {
                method: 'delete',
                params,
            }
        } else if (method == 'put') {
            http = {
                method: 'put',
                data: JSON.stringify(params)
            }
        } else {
            http = {
                method: 'get',
                params,
            }
        }

        http.url = store.state.httpUrl + url

        if (url.indexOf('http') != -1) {
            http.url = url
        }
        headers['content-type'] = 'application/json;charset=utf-8'

        http.headers = headers
        http.timeout = 1000 * 6 * 10
        axios(http).then(res => {
                var res_code = res.status.toString();
                if (res_code.charAt(0) == 2 && res.data) {
                    resolve(res.data);
                } else {
                    Notify('服务器响应异常')
                    reject('服务响应失败=>' + res_code)
                }
            })
            .catch(error => {
                Notify(error);
                reject('失败=>' + error)

            })
    })
}


export default { ajax };