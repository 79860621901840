import Vue from 'vue'
import App from './App.vue'
// import store from './store'
import './assets/css/afterList.css'
import router from './router'
import Vant from 'vant';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap'
import http from './api/http.js'
Vue.prototype.$http = http
import 'vant/lib/index.css';
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false
    // 引入cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies)
    // md5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 使用钩子函数对路由进行权限拦截跳转
// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
//     let roles = VueCookies.get('userId');
//     let path = to.path.split('/');
//     if (path[1] == 'sendAfter') {
//         next()
//     } else if (to.path == '/sendAfter' || to.path == '/afterDetails') {
//         next();
//     } else if (!roles && to.path !== '/login') {
//         next('/login');
//     } else {
//         next()
//     }
// })

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')