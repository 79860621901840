<template>
  <div class="app-container">
      <div class="win_cs" >
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
  .app-container{
      background-color: #F4F4F4;
    }
  @media screen and (min-width: 0px) and (max-width: 200px){
    .win_cs{
      width: 200px;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 575px){
    .win_cs{
      width: 95%;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  @media screen and (min-width: 575px) and (max-width: 19200px){
    .app-container{
      width: 575px;
      margin: 0 auto;
      overflow: hidden;
      padding: 0px 15px;
    }
  }
</style>