import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../components/page/login.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../components/page/login.vue')
    },
    {
        path: '/afterOrder',
        name: 'AfterOrder',
        component: () =>
            import ('../components/page/afterOrder.vue')
    },
    {
        path: '/addOrder',
        name: 'AddOrder',
        component: () =>
            import ('../components/page/addOrder.vue')
    },
    {
        path: '/referOrder',
        name: 'ReferOrder',
        component: () =>
            import ('../components/page/referOrder.vue')
    },
    {
        path: '/afterList',
        name: 'AfterList',
        component: () =>
            import ('../components/page/afterList.vue')
    },
    {
        path: '/afterDetails',
        name: 'AfterDetails',
        component: () =>
            import ('../components/page/afterDetails.vue')
    },
    {
        path: '/sendAfter/*',
        name: 'SendAfter',
        component: () =>
            import ('../components/page/sendAfter.vue')
    },
    {
        path: '/sendAfter',
        name: 'SendAfter',
        component: () =>
            import ('../components/page/sendAfter.vue')
    },
    {
        path: '/*',
        name: '404',
        component: () =>
            import ('../components/page/404.vue')
    },
]

const router = new Router({
    routes
})
export default router